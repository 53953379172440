@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
@import url(https://fonts.googleapis.com/css?family=Quicksand:400,700);
/*
  Simple Grid
  Project Page - http://thisisdallas.github.com/Simple-Grid/
  Author - Dallas Bass
  Site - http://dallasbass.com
*/


[class*='grid'],
[class*='col-'],
[class*='mobile-'],
.grid:after {
	box-sizing: border-box;	
}

[class*='col-'] {
	float: left;
  	min-height: 1px;
	padding-right: 15px; /* column-space */
}

[class*='col-'] [class*='col-']:last-child {
	padding-right: 0;
}

.grid {
	width: 100%;
	max-width: 1140px;
	min-width: 748px; /* when using padded grid on ipad in portrait mode, width should be viewport-width - padding = (768 - 20) = 748. actually, it should be even smaller to allow for padding of grid containing element */
	margin: 0 auto;
	overflow: hidden;
}

.grid-wrapper {
	margin-right: -15px;
	overflow: hidden;
	padding-bottom: 5px;
}

.grid:after {
	content: "";
	display: table;
	clear: both;
}

.grid-pad {
	padding-top: 15px;
	padding-left: 15px; /* grid-space to left */
	padding-right: 0; /* grid-space to right: (grid-space-left - column-space) e.g. 20px-20px=0 */
}

.push-right {
	float: right;
}

/* Content Columns */

.col-1-1 {
	width: 100%;
}
.col-2-3, .col-8-12 {
	width: 66.66%;
}

.col-1-2, .col-6-12 {
	width: 50%;
}

.col-1-3, .col-4-12 {
	width: 33.33%;
}

.col-1-4, .col-3-12 {
	width: 25%;
}

.col-1-5 {
	width: 20%;
}

.col-1-6, .col-2-12 {
	width: 16.667%;
}

.col-1-7 {
	width: 14.28%;
}

.col-1-8 {
	width: 12.5%;
}

.col-1-9 {
	width: 11.1%;
}

.col-1-10 {
	width: 10%;
}

.col-1-11 {
	width: 9.09%;
}

.col-1-12 {
	width: 8.33%
}

/* Layout Columns */

.col-11-12 {
	width: 91.66%
}

.col-10-12 {
	width: 83.333%;
}

.col-9-12 {
	width: 75%;
}

.col-5-12 {
	width: 41.66%;
}

.col-7-12 {
	width: 58.33%
}

/* Pushing blocks */

.push-2-3, .push-8-12 {
	margin-left: 66.66%;
}

.push-1-2, .push-6-12 {
	margin-left: 50%;
}

.push-1-3, .push-4-12 {
	margin-left: 33.33%;
}

.push-1-4, .push-3-12 {
	margin-left: 25%;
}

.push-1-5 {
	margin-left: 20%;
}

.push-1-6, .push-2-12 {
	margin-left: 16.667%;
}

.push-1-7 {
	margin-left: 14.28%;
}

.push-1-8 {
	margin-left: 12.5%;
}

.push-1-9 {
	margin-left: 11.1%;
}

.push-1-10 {
	margin-left: 10%;
}

.push-1-11 {
	margin-left: 9.09%;
}

.push-1-12 {
	margin-left: 8.33%
}

@media handheld, only screen and (max-width: 768px) {
	.grid {
		width: 100%;
		min-width: 0;
		margin-left: 0;
		margin-right: 0;
		padding-left: 15px; /* grid-space to left */
		padding-right: 10px; /* grid-space to right: (grid-space-left - column-space) e.g. 20px-10px=10px */
	}

	.grid-wrapper {
		margin-right: -10px;
	}

	[class*='col-'] {
		width: auto;
		float: none;
		margin: 10px 0;
		padding-left: 0;
		padding-right: 10px; /* column-space */
	}

	[class*='col-'] [class*='col-'] {
		padding-right: 0;
	}

	/* Mobile Layout */

	[class*='mobile-col-'] {
		float: left;
		margin: 0 0 10px;
		padding-left: 0;
		padding-right: 10px; /* column-space */
		padding-bottom: 0;
	}

	.mobile-col-1-1 {
		width: 100%;
	}
	.mobile-col-2-3, .mobile-col-8-12 {
		width: 66.66%;
	}

	.mobile-col-1-2, .mobile-col-6-12 {
		width: 50%;
	}

	.mobile-col-1-3, .mobile-col-4-12 {
		width: 33.33%;
	}

	.mobile-col-1-4, .mobile-col-3-12 {
		width: 25%;
	}

	.mobile-col-1-5 {
		width: 20%;
	}

	.mobile-col-1-6, .mobile-col-2-12 {
		width: 16.667%;
	}

	.mobile-col-1-7 {
		width: 14.28%;
	}

	.mobile-col-1-8 {
		width: 12.5%;
	}

	.mobile-col-1-9 {
		width: 11.1%;
	}

	.mobile-col-1-10 {
		width: 10%;
	}

	.mobile-col-1-11 {
		width: 9.09%;
	}

	.mobile-col-1-12 {
		width: 8.33%
	}

	/* Layout Columns */

	.mobile-col-11-12 {
		width: 91.66%
	}

	.mobile-col-10-12 {
		width: 83.333%;
	}

	.mobile-col-9-12 {
		width: 75%;
	}

	.mobile-col-5-12 {
		width: 41.66%;
	}

	.mobile-col-7-12 {
		width: 58.33%
	}

	.hide-on-mobile {
		display: none !important;
		width: 0;
		height: 0;
	}
}


.m-calendar{
    display:inline-block
}
.m-calendar table{
    width:100%;
    border-collapse:collapse;
    border-spacing:0;
    table-layout:fixed
}
.m-calendar td{
    padding:8px 0;
    text-align:center;
    cursor:pointer;
    color:#dfe0e4;
    border:1px solid #dfe0e4
}
.m-calendar thead td{
    color:#1385e5;
    font-size:11px;
    font-weight:700;
    text-transform:uppercase;
    font-size:12px
}
.m-calendar tbody td{
    color:#666
}
.m-calendar tbody td:hover{
    background:#1385e5;
    border-color:#1385e5;
    color:#fff
}
.m-calendar .current-day{
    color:#1385e5;
    font-weight:700
}
.m-calendar .next-month,.m-calendar .prev-month{
    color:#999
}
.m-calendar .toolbar{
    line-height:30px;
    color:#1385e5;
    text-align:center;
    margin-bottom:13px
}
.m-calendar .toolbar button{
    position:relative;
    width:30px;
    height:30px;
    line-height:30px;
    color:#fff;
    border:1px solid #1385e5;
    border-radius:50%;
    background:#1385e5;
    font-size:20px;
    padding:0;
    text-align:center;
    outline:0;
    z-index:5;
    cursor:pointer
}
.m-calendar .toolbar .prev-month{
    float:left
}
.m-calendar .toolbar .next-month{
    float:right
}
.m-calendar .toolbar .current-date{
    color:#1385e5
}
.m-time{
    color:#fff;
    padding-top:50px
}
.m-time .showtime{
    text-align:center
}
.m-time .separater{
    display:inline-block;
    font-size:32px;
    font-weight:700;
    color:#1385e5;
    width:32px;
    height:65px;
    line-height:65px;
    text-align:center
}
.m-time .time-text{
    position:relative;
    left:-10px;
    font-size:15px;
    color:#1385e5;
    margin-top:7px;
    margin-bottom:10px
}
.m-time .sliders{
    padding:0 10px
}
.m-time .time{
    width:65px;
    height:65px;
    display:inline-block;
    font-size:38px;
    line-height:65px;
    background-color:#1385e5;
    border-radius:3px;
    text-align:center
}
.u-slider-time{
    position:relative;
    display:inline-block;
    background-color:#dfe0e4;
    border-radius:3px;
    height:4px;
    width:100%;
    cursor:pointer
}
.u-slider-time .value{
    position:absolute;
    background-color:#1385e5;
    border-radius:3px;
    top:0;
    height:100%
}
.u-slider-time .handle{
    position:absolute;
    width:4px;
    height:4px
}
.u-slider-time .handle:after{
    position:relative;
    display:block;
    content:'';
    top:-10px;
    left:-12px;
    width:24px;
    height:24px;
    background-color:#fff;
    border:3px solid #1385e5;
    border-radius:50%;
    cursor:pointer
}
.im-btn{
    display:inline-block;
    background-color:#fff;
    border:0;
    outline:0;
    cursor:pointer;
    line-height:1
}
.im-btn:before{
    margin-right:6px
}
.m-input-moment{
    display:inline-block;
    width:330px;
    padding:12px 15px;
    border-radius:3px;
    border:1px solid #dfe0e4
}
.m-input-moment .options{
    width:100%;
    display:inline-block;
    margin-bottom:4px
}
.m-input-moment .options button{
    float:left;
    width:50%;
    color:#1385e5;
    text-align:center;
    font-size:16px;
    padding:7px;
    border:1px solid #1385e5;
    border-radius:3px
}
.m-input-moment .options button:first-child{
    border-top-right-radius:0;
    border-bottom-right-radius:0
}
.m-input-moment .options button:last-child{
    border-top-left-radius:0;
    border-bottom-left-radius:0
}
.m-input-moment .options button.is-active{
    color:#fff;
    background-color:#1385e5
}
.m-input-moment .tab{
    display:none;
    height:310px
}
.m-input-moment .tab.is-active{
    display:block
}
.m-input-moment .tabs{
    margin-bottom:11px
}
.m-input-moment .btn-save{
    display:block;
    margin-top:10px;
    width:100%;
    background-color:#1385e5;
    padding:12px 0;
    text-align:center;
    color:#fff;
    font-size:16px;
    border-radius:3px
}

@font-face {
  font-family: 'redactedregular';
  src: url(/static/media/redacted-regular.5dcdb9f3.eot);
  src: url(/static/media/redacted-regular.5dcdb9f3.eot?#iefix) format('embedded-opentype'), url(/static/media/redacted-regular.aafeeb63.woff2) format('woff2'), url(/static/media/redacted-regular.111d296a.woff) format('woff'), url(/static/media/redacted-regular.a76789d4.ttf) format('truetype'), url(/static/media/redacted-regular.e2d56576.svg#redactedregular) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'redacted_scriptbold';
  src: url(/static/media/redacted-script-bold.4d525e63.eot);
  src: url(/static/media/redacted-script-bold.4d525e63.eot?#iefix) format('embedded-opentype'), url(/static/media/redacted-script-bold.51086298.woff2) format('woff2'), url(/static/media/redacted-script-bold.0e1cf990.woff) format('woff'), url(/static/media/redacted-script-bold.70a39fbc.ttf) format('truetype'), url(/static/media/redacted-script-bold.2988c41e.svg#redacted_scriptbold) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'redacted_scriptlight';
  src: url(/static/media/redacted-script-light.6d1f6c36.eot);
  src: url(/static/media/redacted-script-light.6d1f6c36.eot?#iefix) format('embedded-opentype'), url(/static/media/redacted-script-light.c928a540.woff2) format('woff2'), url(/static/media/redacted-script-light.427448d2.woff) format('woff'), url(/static/media/redacted-script-light.139281d7.ttf) format('truetype'), url(/static/media/redacted-script-light.f6ec26c3.svg#redacted_scriptlight) format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'redacted_scriptregular';
  src: url(/static/media/redacted-script-regular.d7e5350e.eot);
  src: url(/static/media/redacted-script-regular.d7e5350e.eot?#iefix) format('embedded-opentype'), url(/static/media/redacted-script-regular.60c80664.woff2) format('woff2'), url(/static/media/redacted-script-regular.dbcb1268.woff) format('woff'), url(/static/media/redacted-script-regular.c6bf56e5.ttf) format('truetype'), url(/static/media/redacted-script-regular.c7b4e593.svg#redacted_scriptregular) format('svg');
  font-weight: normal;
  font-style: normal;
}
.fa-ca.fa-chevron-right {
  width: 100%;
  height: 100%;
  background: url(/static/media/chevron-right.aadec982.svg) no-repeat center;
  background-size: 70%;
  display: inline-block;
  opacity: 0.9;
}
.fa-ca.fa-chevron-left {
  width: 100%;
  height: 100%;
  background: url(/static/media/chevron-left.7236b439.svg) no-repeat center;
  background-size: 70%;
  display: inline-block;
  opacity: 0.9;
}
html,
html,
body,
ul,
li,
ol {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}
body {
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1px;
  width: 100%;
  height: 100%;
  cursor: default;
  background-color: #172e3d;
}
html,
#root {
  height: 100%;
}
a {
  text-decoration: none;
  color: #5E5E5E;
}
a:hover {
  color: #141414;
}
img {
  max-width: 100%;
}
br {
  min-height: 0px;
  display: block;
  content: '';
}
.container {
  max-width: 1150px;
  margin: auto;
  padding: 0 15px;
}
.container.medium {
  max-width: 1000px;
}
.container.small {
  max-width: 900px;
}
@media (max-width: 350px) {
  .container.full_mobile {
    padding: 0;
  }
}
h1,
h2,
h3 {
  font-weight: normal;
}
h3 {
  margin: 0;
}
h2 {
  margin: 0;
}
h2.placeholder {
  text-align: center;
  margin-top: 20px;
  color: #5d5d5d;
}
h1 {
  margin-bottom: 5px;
}
p {
  margin: 0;
  text-align: justify;
}
input {
  background: none;
}
div.icon-circle {
  width: 2em;
  height: 2em;
  border-radius: 50%;
  line-height: 2em;
  text-align: center;
}
.truncate {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.float-left {
  float: left;
}
#wrapper {
  position: relative;
  min-height: 100%;
  padding-bottom: 300px;
  padding-top: 49px;
}
@media all and (max-width: 768px) {
  #wrapper {
    padding-bottom: 35px;
  }
}
.vertical-alignment-helper {
  display: table;
  height: 100%;
  width: 100%;
  pointer-events: none;
}
.vertical-align-center {
  display: table-cell;
  vertical-align: middle;
}
.vertical-align-center > * {
  pointer-events: all;
}
.text-center {
  text-align: center;
}
.color-red {
  color: red !important;
}
a.button {
  padding: 5px 25px;
  border: solid #3B3B3B 2px;
  border-radius: 3px;
  color: #3B3B3B;
  margin-top: 20px;
  display: inline-block;
  text-transform: uppercase;
  transition: background 300ms ease;
}
a.button:hover {
  background: rgba(0, 0, 0, 0.2);
}
.App {
  background-color: #eeeeee;
}
*.clickable {
  cursor: pointer;
  color: #204056;
  transition: color 500ms;
}
*.clickable:hover {
  transition: none;
  color: #3996d6;
}
*.clickable-light {
  cursor: pointer;
  color: white;
  transition: color 300ms;
}
*.clickable-light:hover {
  transition: none;
  color: #b8ebec;
}
label span.required {
  color: red !important;
}
::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a4a4a4;
  font-style: italic;
  font-size: 14px;
  opacity: 1;
  /* Firefox */
}
:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a4a4a4;
  font-style: italic;
  font-size: 14px;
  opacity: 1;
  /* Firefox */
}
::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a4a4a4;
  font-style: italic;
  font-size: 14px;
  opacity: 1;
  /* Firefox */
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #a4a4a4;
  font-style: italic;
  font-size: 14px;
  opacity: 1;
  /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a4a4a4;
  font-style: italic;
  font-size: 14px;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a4a4a4;
  font-style: italic;
  font-size: 14px;
}
.content-box {
  background-color: rgba(208, 208, 208, 0.2);
}
@media all and (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}
div.Header {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s;
  pointer-events: none;
  height: 100vh;
}
div.Header *,
div.Header.open {
  pointer-events: initial;
}
div.Header .navbar {
  width: 100%;
  height: 50px;
  background-color: #1CABA0;
  overflow: hidden;
  position: absolute;
  top: 0;
}
div.Header .navbar .logo {
  margin-top: 8px;
  height: 35px;
  max-width: calc(100% - 45px);
}
div.Header .swipe {
  height: calc(100vh - 50px);
  width: 25px;
  transition: width 0.3s;
  position: fixed;
  top: 50px;
}
div.Header .Menu {
  max-width: 250px;
  height: calc(100vh - 50px);
  background: white;
  overflow: auto;
  position: relative;
  left: -250px;
  transition: left 0.4s;
}
div.Header .Menu ul {
  margin-top: 10px;
}
div.Header .Menu ul li {
  list-style: none;
}
div.Header .Menu ul li a {
  padding: 12px 25px;
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 200;
}
div.Header .Menu ul li a.active {
  border-left: solid #204056 3px;
}
div.Header .Menu ul li a.header_button {
  background: transparent;
  border: solid white 1px;
  border-radius: 20px;
  padding: 5px 25px;
  margin: 10px 7px;
  font-weight: normal;
}
div.Header .Menu ul li a.header_button.special {
  background-color: white;
  color: #1CABA0;
}
div.Header.open {
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.1);
}
div.Header.open .Menu {
  left: 0;
}
div.Header.open .swipe {
  width: 250px;
}
div.Header.open .hamburger--arrow .hamburger-inner:before {
  -webkit-transform: translate3d(-6px, 1.7px, 0) rotate(-45deg) scaleX(0.6);
          transform: translate3d(-6px, 1.7px, 0) rotate(-45deg) scaleX(0.6);
}
div.Header.open .hamburger--arrow .hamburger-inner:after {
  -webkit-transform: translate3d(-6px, -1.7px, 0) rotate(45deg) scaleX(0.6);
          transform: translate3d(-6px, -1.7px, 0) rotate(45deg) scaleX(0.6);
}
@media all and (min-width: 768px) {
  div.Header .swipe {
    height: 50px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    pointer-events: none;
  }
  div.Header.open {
    background-color: transparent;
  }
  div.Header.open .swipe {
    width: 100%;
  }
  div.Header .Menu {
    max-width: 1150px;
    margin: auto;
    padding: 0 15px;
    height: auto;
    height: initial;
    position: static;
    position: initial;
    left: 0;
    background: none;
    text-align: right;
    transition: none;
    pointer-events: none;
  }
  div.Header .Menu ul {
    margin-top: 0px;
    pointer-events: initial;
    display: inline-block;
  }
  div.Header .Menu ul li {
    display: inline-block;
  }
  div.Header .Menu ul li a {
    padding: 16px 16px;
    height: 100%;
    display: block;
    color: white;
  }
  div.Header .Menu ul li a:hover {
    border-bottom: solid #e7e7e7 1px;
  }
  div.Header .Menu ul li a.active {
    border: none;
    border-bottom: solid #e7e7e7 1px;
  }
  div.Header .hamburger {
    display: none !important;
  }
}
div.Header .burger {
  float: left;
}
div.Header .hamburger {
  height: 100%;
  font: inherit;
  display: inline-block;
  overflow: visible;
  margin: 0;
  padding: 16px;
  cursor: pointer;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  text-transform: none;
  color: inherit;
  border: 0;
  background-color: transparent;
}
div.Header .hamburger:hover {
  opacity: .7;
}
div.Header .hamburger .hamburger-box {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 18px;
}
div.Header .hamburger .hamburger-inner {
  top: 50%;
  display: block;
  margin-top: -2px;
  position: absolute;
  width: 100%;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  border-radius: 4px;
  background-color: white;
}
div.Header .hamburger .hamburger-inner:after {
  position: absolute;
  width: 100%;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  border-radius: 4px;
  background-color: white;
  display: block;
  content: "";
  bottom: -7px;
}
div.Header .hamburger .hamburger-inner:before {
  position: absolute;
  width: 100%;
  height: 3px;
  transition-timing-function: ease;
  transition-duration: .15s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  border-radius: 4px;
  background-color: white;
  display: block;
  content: "";
  top: -7px;
}
div.Header .hamburger .hamburger--minus .hamburger-inner:after {
  transition: bottom 0.08s ease-out 0s, top 0.08s ease-out 0s, opacity 0s linear;
}
div.Header .hamburger .hamburger--minus .hamburger-inner:before {
  transition: bottom 0.08s ease-out 0s, top 0.08s ease-out 0s, opacity 0s linear;
}
.HeroTitle {
  background-color: #404F54;
  max-height: 0;
  max-height: 0vh;
  color: white;
  overflow: hidden;
  box-shadow: inset 0px 10px 29px -19px rgba(0, 0, 0, 0.55);
}
.HeroTitle > .container {
  padding-top: 2em;
  padding-bottom: 2em;
  margin-top: -8em;
  transition: margin-top 1s;
}
.HeroTitle.open {
  max-height: 410px;
  max-height: 100vh;
  transition: max-height 0.1s;
}
.HeroTitle.open > .container {
  margin-top: 0;
}
.HeroTitle > h2 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
}
.HeroTitle > p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  color: rgba(255, 255, 255, 0.54);
  margin-top: 10px;
}
.HeroTitle .headerExtraText {
  margin-top: 10px;
}
div.warning-hero {
  padding: 18px 18px;
  text-align: center;
  background-color: #f99910;
}
div.warning-hero h5 {
  margin: 0;
  color: white;
  font-weight: normal;
}
@media all and (max-width: 900px) {
  div.Header .Menu ul li a {
    padding: 16px 8px;
  }
}
@media all and (max-width: 768px) {
  div.Header .Menu ul li a {
    padding: 16px 25px;
  }
}
.Footer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 45px;
  background-color: #404F54;
}
.Footer p {
  color: #8C9598;
  margin: 14px 0;
  font-size: 12px;
  line-height: 18px;
}
.Footer .footerLink {
  margin: 20px 0px;
  font-size: 13px;
  display: block;
  cursor: pointer;
  color: #8C9598;
  /*&:not(:first-child):before{
      content: "•";
      margin: 0 5px;
    }*/
}
@media all and (max-width: 768px) {
  .Footer {
    display: none;
  }
  .Footer .footerLink,
  .Footer p {
    margin: 5px 0;
  }
}
.Footer h2 {
  font-size: 16px;
  text-transform: uppercase;
  color: #8C9598;
}
.Footer .logo {
  width: 150px;
  margin-left: 20px;
}
.bad-config {
  background-color: red;
  min-height: 25px;
}
.container .Box {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  padding: 16px;
  padding-bottom: 0;
  margin-top: 15px;
  background-color: white;
}
.container .Box p {
  word-wrap: break-word;
  line-height: 1.6;
  color: #404040;
}
.container .Box > p.instructions {
  color: #808080;
  font-size: 12px;
  margin-top: -15px;
  margin-bottom: 15px;
}
.container .Box h3 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}
.container .Box .Collapsible__trigger {
  padding: 15px;
  margin: -15px;
  margin-bottom: 0;
  display: block;
}
.container .Box .Collapsible__trigger h3 {
  transition: margin-bottom 0.3s;
  margin-bottom: 0;
}
.container .Box .Collapsible__trigger .fa-angle-down {
  float: right;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  transition: -webkit-transform .3s;
  transition: transform .3s;
  transition: transform .3s, -webkit-transform .3s;
}
.container .Box .Collapsible__trigger.is-open .fa-angle-down {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.container .Box .password-input {
  background-color: #ececec;
  padding: 1px 10px 10px 10px;
  margin: 10px -5px 0 -5px;
  border-radius: 6px;
}
.container .Box .box-content {
  padding: 16px;
  margin: 0 -16px;
  margin-top: 0;
  padding-top: 0;
}
.container ul.list {
  margin-bottom: 20px;
  margin-top: 10px;
}
.container ul.list li {
  text-indent: -12px;
  word-wrap: break-word;
  line-height: 1.6;
  color: #404040;
  list-style: none;
  margin-left: 15px;
}
.container ul.list li:before {
  content: "-";
  font-weight: normal;
  font-size: 1.2em;
  margin-right: 5px;
}
.container ul.list.simple li {
  text-indent: 0;
  text-indent: initial;
}
.container ul.list.simple li:before {
  content: none;
}
.container span.icon {
  padding: 0.4em;
  width: 1.2em;
  display: block;
}
.container .Field {
  position: relative;
  margin-top: 10px;
}
.container .Field.changed-value {
  border-radius: 6px;
  padding: 1px 6px;
  margin: -1px -6px;
  margin-top: 9px;
  background-size: 200% 100%;
  background-position: left 0% center;
  transition: background-position 0.8s ease-in-out;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='100,0 100,100 0,100, 0,0' style='fill:%23C7E3C1%3B' /%3E%3Cpolygon points='50,0 50,100 0,100, 0,0' style='fill:%23fff3bd%3B' /%3E%3C/svg%3E");
}
.container .Field.changed-value input,
.container .Field.changed-value textarea {
  background-color: inherit;
}
.container .Field.changed-value[progress="100"] {
  background-position: left -100% center;
}
.container .Field.changed-value[progress="99"] {
  background-position: left -99% center;
}
.container .Field.changed-value[progress="98"] {
  background-position: left -98% center;
}
.container .Field.changed-value[progress="97"] {
  background-position: left -97% center;
}
.container .Field.changed-value[progress="96"] {
  background-position: left -96% center;
}
.container .Field.changed-value[progress="95"] {
  background-position: left -95% center;
}
.container .Field.changed-value[progress="94"] {
  background-position: left -94% center;
}
.container .Field.changed-value[progress="93"] {
  background-position: left -93% center;
}
.container .Field.changed-value[progress="92"] {
  background-position: left -92% center;
}
.container .Field.changed-value[progress="91"] {
  background-position: left -91% center;
}
.container .Field.changed-value[progress="90"] {
  background-position: left -90% center;
}
.container .Field.changed-value[progress="89"] {
  background-position: left -89% center;
}
.container .Field.changed-value[progress="88"] {
  background-position: left -88% center;
}
.container .Field.changed-value[progress="87"] {
  background-position: left -87% center;
}
.container .Field.changed-value[progress="86"] {
  background-position: left -86% center;
}
.container .Field.changed-value[progress="85"] {
  background-position: left -85% center;
}
.container .Field.changed-value[progress="84"] {
  background-position: left -84% center;
}
.container .Field.changed-value[progress="83"] {
  background-position: left -83% center;
}
.container .Field.changed-value[progress="82"] {
  background-position: left -82% center;
}
.container .Field.changed-value[progress="81"] {
  background-position: left -81% center;
}
.container .Field.changed-value[progress="80"] {
  background-position: left -80% center;
}
.container .Field.changed-value[progress="79"] {
  background-position: left -79% center;
}
.container .Field.changed-value[progress="78"] {
  background-position: left -78% center;
}
.container .Field.changed-value[progress="77"] {
  background-position: left -77% center;
}
.container .Field.changed-value[progress="76"] {
  background-position: left -76% center;
}
.container .Field.changed-value[progress="75"] {
  background-position: left -75% center;
}
.container .Field.changed-value[progress="74"] {
  background-position: left -74% center;
}
.container .Field.changed-value[progress="73"] {
  background-position: left -73% center;
}
.container .Field.changed-value[progress="72"] {
  background-position: left -72% center;
}
.container .Field.changed-value[progress="71"] {
  background-position: left -71% center;
}
.container .Field.changed-value[progress="70"] {
  background-position: left -70% center;
}
.container .Field.changed-value[progress="69"] {
  background-position: left -69% center;
}
.container .Field.changed-value[progress="68"] {
  background-position: left -68% center;
}
.container .Field.changed-value[progress="67"] {
  background-position: left -67% center;
}
.container .Field.changed-value[progress="66"] {
  background-position: left -66% center;
}
.container .Field.changed-value[progress="65"] {
  background-position: left -65% center;
}
.container .Field.changed-value[progress="64"] {
  background-position: left -64% center;
}
.container .Field.changed-value[progress="63"] {
  background-position: left -63% center;
}
.container .Field.changed-value[progress="62"] {
  background-position: left -62% center;
}
.container .Field.changed-value[progress="61"] {
  background-position: left -61% center;
}
.container .Field.changed-value[progress="60"] {
  background-position: left -60% center;
}
.container .Field.changed-value[progress="59"] {
  background-position: left -59% center;
}
.container .Field.changed-value[progress="58"] {
  background-position: left -58% center;
}
.container .Field.changed-value[progress="57"] {
  background-position: left -57% center;
}
.container .Field.changed-value[progress="56"] {
  background-position: left -56% center;
}
.container .Field.changed-value[progress="55"] {
  background-position: left -55% center;
}
.container .Field.changed-value[progress="54"] {
  background-position: left -54% center;
}
.container .Field.changed-value[progress="53"] {
  background-position: left -53% center;
}
.container .Field.changed-value[progress="52"] {
  background-position: left -52% center;
}
.container .Field.changed-value[progress="51"] {
  background-position: left -51% center;
}
.container .Field.changed-value[progress="50"] {
  background-position: left -50% center;
}
.container .Field.changed-value[progress="49"] {
  background-position: left -49% center;
}
.container .Field.changed-value[progress="48"] {
  background-position: left -48% center;
}
.container .Field.changed-value[progress="47"] {
  background-position: left -47% center;
}
.container .Field.changed-value[progress="46"] {
  background-position: left -46% center;
}
.container .Field.changed-value[progress="45"] {
  background-position: left -45% center;
}
.container .Field.changed-value[progress="44"] {
  background-position: left -44% center;
}
.container .Field.changed-value[progress="43"] {
  background-position: left -43% center;
}
.container .Field.changed-value[progress="42"] {
  background-position: left -42% center;
}
.container .Field.changed-value[progress="41"] {
  background-position: left -41% center;
}
.container .Field.changed-value[progress="40"] {
  background-position: left -40% center;
}
.container .Field.changed-value[progress="39"] {
  background-position: left -39% center;
}
.container .Field.changed-value[progress="38"] {
  background-position: left -38% center;
}
.container .Field.changed-value[progress="37"] {
  background-position: left -37% center;
}
.container .Field.changed-value[progress="36"] {
  background-position: left -36% center;
}
.container .Field.changed-value[progress="35"] {
  background-position: left -35% center;
}
.container .Field.changed-value[progress="34"] {
  background-position: left -34% center;
}
.container .Field.changed-value[progress="33"] {
  background-position: left -33% center;
}
.container .Field.changed-value[progress="32"] {
  background-position: left -32% center;
}
.container .Field.changed-value[progress="31"] {
  background-position: left -31% center;
}
.container .Field.changed-value[progress="30"] {
  background-position: left -30% center;
}
.container .Field.changed-value[progress="29"] {
  background-position: left -29% center;
}
.container .Field.changed-value[progress="28"] {
  background-position: left -28% center;
}
.container .Field.changed-value[progress="27"] {
  background-position: left -27% center;
}
.container .Field.changed-value[progress="26"] {
  background-position: left -26% center;
}
.container .Field.changed-value[progress="25"] {
  background-position: left -25% center;
}
.container .Field.changed-value[progress="24"] {
  background-position: left -24% center;
}
.container .Field.changed-value[progress="23"] {
  background-position: left -23% center;
}
.container .Field.changed-value[progress="22"] {
  background-position: left -22% center;
}
.container .Field.changed-value[progress="21"] {
  background-position: left -21% center;
}
.container .Field.changed-value[progress="20"] {
  background-position: left -20% center;
}
.container .Field.changed-value[progress="19"] {
  background-position: left -19% center;
}
.container .Field.changed-value[progress="18"] {
  background-position: left -18% center;
}
.container .Field.changed-value[progress="17"] {
  background-position: left -17% center;
}
.container .Field.changed-value[progress="16"] {
  background-position: left -16% center;
}
.container .Field.changed-value[progress="15"] {
  background-position: left -15% center;
}
.container .Field.changed-value[progress="14"] {
  background-position: left -14% center;
}
.container .Field.changed-value[progress="13"] {
  background-position: left -13% center;
}
.container .Field.changed-value[progress="12"] {
  background-position: left -12% center;
}
.container .Field.changed-value[progress="11"] {
  background-position: left -11% center;
}
.container .Field.changed-value[progress="10"] {
  background-position: left -10% center;
}
.container .Field.changed-value[progress="9"] {
  background-position: left -9% center;
}
.container .Field.changed-value[progress="8"] {
  background-position: left -8% center;
}
.container .Field.changed-value[progress="7"] {
  background-position: left -7% center;
}
.container .Field.changed-value[progress="6"] {
  background-position: left -6% center;
}
.container .Field.changed-value[progress="5"] {
  background-position: left -5% center;
}
.container .Field.changed-value[progress="4"] {
  background-position: left -4% center;
}
.container .Field.changed-value[progress="3"] {
  background-position: left -3% center;
}
.container .Field.changed-value[progress="2"] {
  background-position: left -2% center;
}
.container .Field.changed-value[progress="1"] {
  background-position: left -1% center;
}
.container .Field.invalid {
  background-color: rgba(255, 16, 0, 0.26);
  background-image: none;
}
.container .Field .objectives-container {
  width: 100%;
}
.container .Field label.objective {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  color: #454545;
  /*@media all and (min-width: 800px) {
          width: 50%;
        }*/
}
.container .Field label.objective:not(:first-child) {
  margin-top: 8px;
}
.container .Field label > span {
  color: #204056;
  font-size: 13px;
  visibility: visible;
  line-height: 19px;
}
.container .Field label > input:not([type=checkbox]),
.container .Field label textarea {
  box-sizing: border-box;
  border: none;
  font-family: 'Roboto', sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  width: 100%;
  font-size: 16px;
  line-height: 1.6;
  outline: none;
  color: #404040;
  transition: border-bottom 0.3s;
}
.container .Field label > input:not([type=checkbox]):focus,
.container .Field label textarea:focus {
  border-bottom: 2px solid #204056;
}
.container .Field label > .field-value {
  min-height: 25px;
  word-wrap: break-word;
  line-height: 1.6;
  color: #404040;
  text-align: justify;
}
.container .Field label > .field-value > .fa-pencil-alt {
  cursor: pointer;
}
.container .Field label input:not([type=checkbox]) {
  height: 30px;
}
.container .Field label textarea {
  resize: vertical;
}
.container .Field.editable label > .field-value {
  padding-right: 25px;
}
.container .Field h2,
.container .Field p {
  font-weight: 400;
}
.container .Field .fa-pencil-alt {
  position: absolute;
  bottom: 4px;
  right: 4px;
}
.container .Field .select_custom_result {
  display: inline-block;
  margin: 8px 8px 0 0;
  border: 1px solid #e6e6e6;
  background: #ffffff;
  padding: 2px 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: rgba(208, 208, 208, 0.2);
  border-radius: 6px;
}
.container .Field p.instructions {
  color: #808080;
  font-size: 12px;
}
.container .Field span.changed-field-icons,
.container .dropzone span.changed-field-icons {
  font-size: 13px;
  float: right;
  background: rgba(206, 187, 162, 0.37);
  padding: 1px 3px;
  border-radius: 5px;
  margin-right: -6px;
  margin-top: -1px;
}
.container .Field span.changed-field-icons .svg-inline--fa,
.container .dropzone span.changed-field-icons .svg-inline--fa {
  float: right;
  cursor: pointer;
}
.container .Field span.changed-field-icons svg,
.container .dropzone span.changed-field-icons svg {
  cursor: pointer;
  margin: 2px 3px;
}
.container .Field span.changed-field-icons svg.fa-check,
.container .dropzone span.changed-field-icons svg.fa-check {
  color: green;
  margin-right: 5px;
}
.container .Field span.changed-field-icons svg.fa-times,
.container .dropzone span.changed-field-icons svg.fa-times {
  color: #d40000;
}
.container .Field span.changed-field-icons.absolute,
.container .dropzone span.changed-field-icons.absolute {
  position: absolute;
  right: 6px;
}
.container .Field.saving,
.container .dropzone.saving {
  pointer-events: none;
}
.container .Field.saving span.changed-field-icons,
.container .dropzone.saving span.changed-field-icons {
  pointer-events: none;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.container a.url {
  color: #38b7b9;
}
.container .loading .Field input,
.container .loading .Field .field-value {
  font-size: 20px;
  font-family: redacted_scriptlight !important;
}
.container .List {
  margin-top: 10px;
  padding-bottom: 20px;
  padding-top: 5px;
  overflow: hidden;
}
.container .List div[data-packed-list],
.container .List .masonry > div {
  width: 100%;
}
@media all and (min-width: 650px) {
  .container .List div[data-packed-list],
  .container .List .masonry > div {
    width: calc(50% - 10px);
  }
}
@media all and (min-width: 1024px) {
  .container .List div[data-packed-list],
  .container .List .masonry > div {
    width: calc(33.333333% - 10px);
  }
}
.container .List div[data-packed-list] .Box,
.container .List .masonry > div .Box {
  margin-top: 0;
}
@media all and (min-width: 650px) {
  .container .List {
    margin-right: -10px;
  }
}
.container .ResultList div[data-packed-list] {
  width: 100%;
}
@media all and (min-width: 960px) {
  .container .ResultList div[data-packed-list] {
    width: calc(50% - 10px);
  }
}
.container .ResultList div[data-packed-list] .Box {
  margin-top: 0;
}
@media all and (min-width: 960px) {
  .container .ResultList {
    margin-right: -10px;
  }
}
.container .DocumentListItem .header {
  position: relative;
  padding-bottom: 5px;
  border-bottom: solid #d3d3d3 1px;
  margin-bottom: 10px;
}
.container .DocumentListItem .header h3 {
  margin: 0;
  margin-right: 1.8em;
  font-weight: normal;
}
.container .DocumentListItem .header h4 {
  color: #414141;
  font-size: 12px;
  margin: 0;
  margin-top: 3px;
}
.container .DocumentListItem .header .icon {
  position: absolute;
  top: 0px;
  right: 0;
  color: #a8a8a8;
  font-size: 18px;
  width: auto;
}
.container .DocumentListItem p {
  font-weight: 400;
}
.container .Button {
  padding: 8px 12px;
  min-width: 140px;
  border-radius: 4px;
  color: #3b3b3b;
  text-align: center;
  cursor: pointer;
  background: #f3f3f3;
  position: relative;
  top: 0;
  transition: all 0.2s;
  box-shadow: 0 2px 0 rgba(150, 150, 150, 0.5);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-block;
}
.container .Button.block {
  display: block;
  margin-bottom: 10px;
}
.container .Button:hover {
  color: inherit;
}
.container .Button:active {
  transition: none;
  box-shadow: none;
  background: #eeeeee;
  top: 2px;
}
.container .Button.special {
  background: #204056;
  color: white;
  box-shadow: 0 2px 0 rgba(32, 64, 86, 0.5);
}
.container .Button.special:active {
  background: #274e69;
}
.container .Button.margin {
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
.container .Button.danger {
  background: #d84b4b;
  color: white;
}
.container .Button.danger:active {
  background: #dd6060;
}
.container .Button.success {
  background: #5cb85c;
  color: white;
}
.container .Button.success:active {
  background: #4cae4c;
}
.container .Button.info {
  background: #5bc0de;
  color: white;
}
.container .Button.info:active {
  background: #46b8da;
}
.container .dropzone {
  position: relative;
}
.container .dropzone .placeholder {
  text-align: center;
  margin: 30px 0;
  color: rgba(102, 102, 102, 0.27);
}
.container .dropzone h4.placeholder {
  margin: 15px 0;
}
.container .dropzone h4.placeholder br {
  min-height: 0;
}
.container .dropzone .file {
  display: block;
  padding: 6px;
  border-radius: 2px;
  background: #dcf3e2;
  margin: 5px;
  word-wrap: break-word;
}
.container .dropzone .file .fa-times {
  float: right;
  color: #d40000;
}
.container .dropzone .file.temp {
  background: #fadf8c;
}
.container .dropzone .changed-field-icons {
  position: absolute;
  bottom: 2px;
  right: 8px;
}
.container .dropzone.temp {
  background-size: 200% 100%;
  background-position: left 0% center;
  transition: background-position 0.8s ease-in-out;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100' height='100' viewBox='0 0 100 100' preserveAspectRatio='none'%3E%3Cpolygon points='100,0 100,100 0,100, 0,0' style='fill:%23C7E3C1%3B' /%3E%3Cpolygon points='50,0 50,100 0,100, 0,0' style='fill:%23fff3bd%3B' /%3E%3C/svg%3E");
}
.container .dropzone.temp input,
.container .dropzone.temp textarea {
  background-color: inherit;
}
.container .dropzone.temp[progress="100"] {
  background-position: left -100% center;
}
.container .dropzone.temp[progress="99"] {
  background-position: left -99% center;
}
.container .dropzone.temp[progress="98"] {
  background-position: left -98% center;
}
.container .dropzone.temp[progress="97"] {
  background-position: left -97% center;
}
.container .dropzone.temp[progress="96"] {
  background-position: left -96% center;
}
.container .dropzone.temp[progress="95"] {
  background-position: left -95% center;
}
.container .dropzone.temp[progress="94"] {
  background-position: left -94% center;
}
.container .dropzone.temp[progress="93"] {
  background-position: left -93% center;
}
.container .dropzone.temp[progress="92"] {
  background-position: left -92% center;
}
.container .dropzone.temp[progress="91"] {
  background-position: left -91% center;
}
.container .dropzone.temp[progress="90"] {
  background-position: left -90% center;
}
.container .dropzone.temp[progress="89"] {
  background-position: left -89% center;
}
.container .dropzone.temp[progress="88"] {
  background-position: left -88% center;
}
.container .dropzone.temp[progress="87"] {
  background-position: left -87% center;
}
.container .dropzone.temp[progress="86"] {
  background-position: left -86% center;
}
.container .dropzone.temp[progress="85"] {
  background-position: left -85% center;
}
.container .dropzone.temp[progress="84"] {
  background-position: left -84% center;
}
.container .dropzone.temp[progress="83"] {
  background-position: left -83% center;
}
.container .dropzone.temp[progress="82"] {
  background-position: left -82% center;
}
.container .dropzone.temp[progress="81"] {
  background-position: left -81% center;
}
.container .dropzone.temp[progress="80"] {
  background-position: left -80% center;
}
.container .dropzone.temp[progress="79"] {
  background-position: left -79% center;
}
.container .dropzone.temp[progress="78"] {
  background-position: left -78% center;
}
.container .dropzone.temp[progress="77"] {
  background-position: left -77% center;
}
.container .dropzone.temp[progress="76"] {
  background-position: left -76% center;
}
.container .dropzone.temp[progress="75"] {
  background-position: left -75% center;
}
.container .dropzone.temp[progress="74"] {
  background-position: left -74% center;
}
.container .dropzone.temp[progress="73"] {
  background-position: left -73% center;
}
.container .dropzone.temp[progress="72"] {
  background-position: left -72% center;
}
.container .dropzone.temp[progress="71"] {
  background-position: left -71% center;
}
.container .dropzone.temp[progress="70"] {
  background-position: left -70% center;
}
.container .dropzone.temp[progress="69"] {
  background-position: left -69% center;
}
.container .dropzone.temp[progress="68"] {
  background-position: left -68% center;
}
.container .dropzone.temp[progress="67"] {
  background-position: left -67% center;
}
.container .dropzone.temp[progress="66"] {
  background-position: left -66% center;
}
.container .dropzone.temp[progress="65"] {
  background-position: left -65% center;
}
.container .dropzone.temp[progress="64"] {
  background-position: left -64% center;
}
.container .dropzone.temp[progress="63"] {
  background-position: left -63% center;
}
.container .dropzone.temp[progress="62"] {
  background-position: left -62% center;
}
.container .dropzone.temp[progress="61"] {
  background-position: left -61% center;
}
.container .dropzone.temp[progress="60"] {
  background-position: left -60% center;
}
.container .dropzone.temp[progress="59"] {
  background-position: left -59% center;
}
.container .dropzone.temp[progress="58"] {
  background-position: left -58% center;
}
.container .dropzone.temp[progress="57"] {
  background-position: left -57% center;
}
.container .dropzone.temp[progress="56"] {
  background-position: left -56% center;
}
.container .dropzone.temp[progress="55"] {
  background-position: left -55% center;
}
.container .dropzone.temp[progress="54"] {
  background-position: left -54% center;
}
.container .dropzone.temp[progress="53"] {
  background-position: left -53% center;
}
.container .dropzone.temp[progress="52"] {
  background-position: left -52% center;
}
.container .dropzone.temp[progress="51"] {
  background-position: left -51% center;
}
.container .dropzone.temp[progress="50"] {
  background-position: left -50% center;
}
.container .dropzone.temp[progress="49"] {
  background-position: left -49% center;
}
.container .dropzone.temp[progress="48"] {
  background-position: left -48% center;
}
.container .dropzone.temp[progress="47"] {
  background-position: left -47% center;
}
.container .dropzone.temp[progress="46"] {
  background-position: left -46% center;
}
.container .dropzone.temp[progress="45"] {
  background-position: left -45% center;
}
.container .dropzone.temp[progress="44"] {
  background-position: left -44% center;
}
.container .dropzone.temp[progress="43"] {
  background-position: left -43% center;
}
.container .dropzone.temp[progress="42"] {
  background-position: left -42% center;
}
.container .dropzone.temp[progress="41"] {
  background-position: left -41% center;
}
.container .dropzone.temp[progress="40"] {
  background-position: left -40% center;
}
.container .dropzone.temp[progress="39"] {
  background-position: left -39% center;
}
.container .dropzone.temp[progress="38"] {
  background-position: left -38% center;
}
.container .dropzone.temp[progress="37"] {
  background-position: left -37% center;
}
.container .dropzone.temp[progress="36"] {
  background-position: left -36% center;
}
.container .dropzone.temp[progress="35"] {
  background-position: left -35% center;
}
.container .dropzone.temp[progress="34"] {
  background-position: left -34% center;
}
.container .dropzone.temp[progress="33"] {
  background-position: left -33% center;
}
.container .dropzone.temp[progress="32"] {
  background-position: left -32% center;
}
.container .dropzone.temp[progress="31"] {
  background-position: left -31% center;
}
.container .dropzone.temp[progress="30"] {
  background-position: left -30% center;
}
.container .dropzone.temp[progress="29"] {
  background-position: left -29% center;
}
.container .dropzone.temp[progress="28"] {
  background-position: left -28% center;
}
.container .dropzone.temp[progress="27"] {
  background-position: left -27% center;
}
.container .dropzone.temp[progress="26"] {
  background-position: left -26% center;
}
.container .dropzone.temp[progress="25"] {
  background-position: left -25% center;
}
.container .dropzone.temp[progress="24"] {
  background-position: left -24% center;
}
.container .dropzone.temp[progress="23"] {
  background-position: left -23% center;
}
.container .dropzone.temp[progress="22"] {
  background-position: left -22% center;
}
.container .dropzone.temp[progress="21"] {
  background-position: left -21% center;
}
.container .dropzone.temp[progress="20"] {
  background-position: left -20% center;
}
.container .dropzone.temp[progress="19"] {
  background-position: left -19% center;
}
.container .dropzone.temp[progress="18"] {
  background-position: left -18% center;
}
.container .dropzone.temp[progress="17"] {
  background-position: left -17% center;
}
.container .dropzone.temp[progress="16"] {
  background-position: left -16% center;
}
.container .dropzone.temp[progress="15"] {
  background-position: left -15% center;
}
.container .dropzone.temp[progress="14"] {
  background-position: left -14% center;
}
.container .dropzone.temp[progress="13"] {
  background-position: left -13% center;
}
.container .dropzone.temp[progress="12"] {
  background-position: left -12% center;
}
.container .dropzone.temp[progress="11"] {
  background-position: left -11% center;
}
.container .dropzone.temp[progress="10"] {
  background-position: left -10% center;
}
.container .dropzone.temp[progress="9"] {
  background-position: left -9% center;
}
.container .dropzone.temp[progress="8"] {
  background-position: left -8% center;
}
.container .dropzone.temp[progress="7"] {
  background-position: left -7% center;
}
.container .dropzone.temp[progress="6"] {
  background-position: left -6% center;
}
.container .dropzone.temp[progress="5"] {
  background-position: left -5% center;
}
.container .dropzone.temp[progress="4"] {
  background-position: left -4% center;
}
.container .dropzone.temp[progress="3"] {
  background-position: left -3% center;
}
.container .dropzone.temp[progress="2"] {
  background-position: left -2% center;
}
.container .dropzone.temp[progress="1"] {
  background-position: left -1% center;
}
.container .form-description {
  font-size: 0.9em;
}
.container .form-description p {
  margin-bottom: 10px;
}
.container .ChallengeObjectives {
  margin-top: 10px;
}
.container .ChallengeObjectives .challenge-objectives-description {
  font-size: 0.9em;
  padding-top: 5px;
}
.container .ChallengeObjectives .objectives-container {
  padding: 3px 0;
}
.container .label_span {
  color: #204056;
  font-size: 13px;
  visibility: visible;
  line-height: 19px;
}
.container .Attachment.not_editable .grid {
  padding: 0;
  padding-left: 10px;
  margin: 0 -10px;
  min-width: 0;
  width: auto;
  width: initial;
}
.container .Attachment.not_editable .file a {
  padding: 10px 20px;
  padding-right: 45px;
  display: block;
  position: relative;
  word-wrap: break-word;
}
.container .Attachment.not_editable .file a .fa-download {
  position: absolute;
  right: 20px;
  top: 10px;
}
.switch {
  position: relative;
  display: inline-block;
  clear: both;
  width: 35px;
  height: 17px;
  top: 2px;
  margin: 6px 6px;
}
.switch input {
  display: none;
}
.switch .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8b929d;
  transition: .2s;
  border-radius: 17px;
}
.switch .slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .2s;
  border-radius: 50%;
}
.switch input:checked + .slider {
  background-color: #194454;
}
.switch input:focus + .slider {
  box-shadow: 0 0 1px #194454;
}
.switch input:checked + .slider:before {
  -webkit-transform: translateX(18px);
  transform: translateX(18px);
}
.UserHeader .img {
  width: 120px;
  height: 120px;
  float: left;
}
.UserHeader .img span {
  border-radius: 50%;
}
.UserHeader div {
  height: 120px;
  display: table;
}
.UserHeader div span.center {
  display: table-cell;
  vertical-align: middle;
  padding: 10px 20px;
}
.UserHeader div span.center h1 {
  margin: 0;
}
.UserHeader div span.center p {
  margin-top: 10px;
}
@media (max-width: 450px) {
  .UserHeader {
    min-width: calc(50vw);
  }
  .UserHeader .img {
    float: none;
    margin: auto;
  }
  .UserHeader .img span {
    max-width: 170px !important;
    height: 170px !important;
  }
  .UserHeader div {
    display: block;
    margin-top: 10px;
    height: auto;
  }
  .UserHeader div span.center {
    padding: 10px 0;
  }
  .UserHeader div span.center h1 {
    font-size: 25px;
  }
}
.SpannedHeader .Box {
  border-bottom: #204056 2px solid;
}
.SpannedHeader h2 {
  margin: 0;
  color: #204056;
  font-weight: 400;
  text-align: center;
  font-size: 18px;
}
.HeroActions {
  text-align: right;
  float: right;
  margin-bottom: -10px;
  margin-left: 10px;
}
@media all and (max-width: 500px) {
  .HeroActions {
    margin-top: 20px;
  }
}
textarea.NoteText {
  min-width: 100%;
  min-height: 80px;
  resize: none;
  overflow: hidden;
  border: none;
  outline: none;
  box-shadow: none;
  font-family: inherit;
  font-size: 15px;
  font-weight: 400;
}
p.note-content svg.fa-trash-alt {
  float: right;
  position: relative;
  margin-top: -18px;
  cursor: pointer;
}
.Feed .SearchBar,
.Search .SearchBar,
.Feed .TodoList,
.Search .TodoList {
  width: 300px;
  float: left;
}
.Feed .SearchBar .Filter,
.Search .SearchBar .Filter,
.Feed .TodoList .Filter,
.Search .TodoList .Filter,
.Feed .SearchBar .TodoItem,
.Search .SearchBar .TodoItem,
.Feed .TodoList .TodoItem,
.Search .TodoList .TodoItem {
  margin-bottom: 15px;
}
.Feed .SearchBar .Filter .filterLabel,
.Search .SearchBar .Filter .filterLabel,
.Feed .TodoList .Filter .filterLabel,
.Search .TodoList .Filter .filterLabel,
.Feed .SearchBar .TodoItem .filterLabel,
.Search .SearchBar .TodoItem .filterLabel,
.Feed .TodoList .TodoItem .filterLabel,
.Search .TodoList .TodoItem .filterLabel,
.Feed .SearchBar .Filter .todoLabel,
.Search .SearchBar .Filter .todoLabel,
.Feed .TodoList .Filter .todoLabel,
.Search .TodoList .Filter .todoLabel,
.Feed .SearchBar .TodoItem .todoLabel,
.Search .SearchBar .TodoItem .todoLabel,
.Feed .TodoList .TodoItem .todoLabel,
.Search .TodoList .TodoItem .todoLabel {
  color: #204056;
  font-size: 13px;
  visibility: visible;
  line-height: 19px;
  display: block;
}
.Feed .SearchBar .Filter .checkBox,
.Search .SearchBar .Filter .checkBox,
.Feed .TodoList .Filter .checkBox,
.Search .TodoList .Filter .checkBox,
.Feed .SearchBar .TodoItem .checkBox,
.Search .SearchBar .TodoItem .checkBox,
.Feed .TodoList .TodoItem .checkBox,
.Search .TodoList .TodoItem .checkBox {
  display: block;
}
.Feed .SearchBar a.note-button,
.Search .SearchBar a.note-button,
.Feed .TodoList a.note-button,
.Search .TodoList a.note-button {
  text-align: center;
  display: block;
  margin-top: 15px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 2px dashed rgba(102, 102, 102, 0.27);
  border-radius: 5px;
  height: 40px;
  padding-top: 9px;
}
.Feed .SearchBar a.note-button.no-notes,
.Search .SearchBar a.note-button.no-notes,
.Feed .TodoList a.note-button.no-notes,
.Search .TodoList a.note-button.no-notes {
  height: 120px;
  padding-top: 48px;
}
.Feed .searchResults,
.Search .searchResults {
  width: calc(100% - 315px);
  float: left;
  margin-left: 15px;
}
.Feed .searchResults h1.placeholder,
.Search .searchResults h1.placeholder {
  text-align: center;
  margin: auto;
  margin-top: 40px;
  color: gray;
  max-width: 600px;
}
.Feed .searchResults .ResultList.loading,
.Search .searchResults .ResultList.loading {
  margin-top: 80px;
}
@media all and (max-width: 700px) {
  .Feed .searchResults,
  .Search .searchResults,
  .Feed .SearchBar,
  .Search .SearchBar,
  .Feed .TodoList,
  .Search .TodoList {
    width: 100%;
    float: none;
    margin-left: 0;
  }
}
.searchInput {
  position: relative;
  display: block;
}
.searchInput .fa-search {
  right: 8px;
  top: 8px;
  cursor: pointer;
  position: absolute;
  color: rgba(255, 255, 255, 0.6);
}
.searchInput .fa-search:hover {
  color: rgba(255, 255, 255, 0.8);
}
.searchInput input {
  box-sizing: border-box;
  border: none;
  font-family: 'Roboto', sans-serif;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  caret-color: white;
  display: block;
  width: 100%;
  margin-top: 10px;
  font-size: 18px;
  line-height: 1.6;
  outline: none;
  color: rgba(255, 255, 255, 0.8);
  transition: border-bottom 0.3s;
}
.searchInput input:focus {
  border-bottom: 2px solid rgba(255, 255, 255, 0.8);
}
.searchInput.dark .fa-search {
  color: rgba(0, 0, 0, 0.6);
}
.searchInput.dark .fa-search:hover {
  color: rgba(0, 0, 0, 0.8);
}
.searchInput.dark input {
  border-bottom: 2px solid rgba(0, 0, 0, 0.3);
  color: rgba(0, 0, 0, 0.8);
  caret-color: rgba(0, 0, 0, 0.8);
}
.searchInput.dark input:focus {
  border-bottom: 2px solid rgba(0, 0, 0, 0.8);
}
.UserListItem .img span {
  border-radius: 50%;
}
.UserListItem .info {
  background: rgba(208, 208, 208, 0.2);
  padding: 15px;
  display: block;
  border-radius: 10px;
  margin-top: 15px;
  padding-top: 10px;
}
.UserListItem .info h1 {
  color: #424242;
  font-weight: lighter;
  font-size: 25px;
  border-bottom: #d9d9d9 1px solid;
  display: block;
  padding: 2px 0px;
  margin-top: 0;
}
.UserListItem .info div.details {
  display: inline-block;
  font-size: 14px;
  margin-top: 5px;
}
/*Radio buttons*/
input.checkbox {
  position: absolute;
  left: -9999px;
}
input.checkbox + span {
  position: relative;
  padding-left: 20px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
input.checkbox + span:before {
  content: '';
  position: absolute;
  left: 0;
  top: -15px;
  width: 15px;
  height: 15px;
  border: 1px solid #ddd;
  background: #fff;
}
input.checkbox + span:after {
  content: '';
  width: 9px;
  height: 9px;
  background: #204056;
  position: absolute;
  top: -11px;
  left: 4px;
  transition: all 0.2s ease;
}
input.checkbox:not(:checked) + span:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
input.checkbox:checked + span:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.img span {
  display: block;
  height: 100%;
}
.img.background span {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: auto;
}
.UserBox {
  background: rgba(208, 208, 208, 0.2);
  padding: 6px;
  padding-right: 10px;
  border-radius: 10px;
  margin-top: 10px;
}
.UserBox:not(.fullwidth) {
  display: inline-block;
}
.UserBox img {
  width: 50px;
  border-radius: 50%;
  height: 50px;
  float: left;
  margin-right: 8px;
}
.UserBox p {
  display: table;
  height: 50px;
  margin: 0 !important;
}
.UserBox p > span {
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
  line-height: 20px;
}
.UserBox p > span > span {
  display: block;
  font-weight: 300;
  font-size: 12px;
  margin-top: 1px;
}
.UserBox.selected {
  background: rgba(32, 64, 86, 0.2);
  background: #1CABA0;
}
.UserBox.selected p {
  color: white;
}
.UserSearch .result-wrapper {
  height: 50vh;
  overflow: auto;
  margin: 10px 0;
  transition: height 0.3s;
}
.UserSearch .result-wrapper:empty {
  height: 0vh;
}
.NewsPopup {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  overflow: auto;
  padding: 40px;
}
.NewsPopup .NewsPopup_box {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  max-width: 730px;
  min-height: 500px;
  background: white;
  padding: 0;
}
@media (max-width: 768px) {
  .NewsPopup .NewsPopup_box {
    flex-direction: column;
    max-width: 400px;
  }
}
.NewsPopup .NewsPopup_box .NewsPopup_image {
  flex: 1 1;
  padding-bottom: 60%;
  background-position: center;
  background-size: cover;
}
.NewsPopup .NewsPopup_box .NewsPopup_content {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 25px;
}
.NewsPopup .NewsPopup_box .NewsPopup_content h1 {
  font-weight: lighter;
  line-height: 1.5em;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 20px;
}
.NewsPopup .NewsPopup_box .NewsPopup_content h1:empty {
  display: none;
}
.NewsPopup .NewsPopup_box .NewsPopup_content h4 {
  margin: 0;
  font-weight: lighter;
  line-height: 1.5em;
  text-align: justify;
  width: 100%;
}
.NewsPopup .NewsPopup_box .NewsPopup_content h4 br {
  min-height: 5px;
}
.Form p {
  margin: 0;
  margin: initial;
}
.Form label {
  display: block;
  margin-bottom: 15px;
}
.Form label > span {
  color: #204056;
  font-size: 13px;
  visibility: visible;
  line-height: 19px;
}
.Form label > input:not([type=checkbox]),
.Form label .form-input {
  height: 30px;
  box-sizing: border-box;
  border: none;
  font-family: Roboto, sans-serif;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: block;
  width: 100%;
  font-size: 18px;
  font-weight: 300;
  outline: none;
  transition: border-bottom 0.3s;
}
.Form label > input:not([type=checkbox]):focus,
.Form label .form-input:focus {
  border-bottom: 2px solid #204056;
}
p.formText {
  font-size: 0.8em;
  margin-bottom: 15px;
}
.select2.select2-container--default .select2-selection--single,
.select2.select2-container--default .select2-selection--multiple {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  outline: none;
  transition: border-bottom 0.3s;
  border-radius: 0;
}
.select2.select2-container--default .select2-selection--single .select2-selection__choice,
.select2.select2-container--default .select2-selection--multiple .select2-selection__choice {
  margin: 5px 5px 0 0;
  border: 1px solid #e6e6e6;
  background: #fff;
  border-radius: 4px;
  padding: 2px 8px;
}
.select2.select2-container--default .select2-selection--single {
  height: 30px;
}
.select2.select2-container--default .select2-selection--single .select2-selection__rendered {
  padding: 0;
  line-height: 30px;
}
.select2.select2-container--default.select2-container--open .select2-selection--single {
  border-bottom: 2px solid #204056;
  padding: 0;
}
.select2.select2-container--default.select2-container--open .select2-selection--multiple {
  border-bottom: 2px solid #204056;
}
.select2-container .select2-dropdown--below {
  margin-top: 5px;
}
.select2-container .select2-dropdown {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border: 0;
}
.select2-container .select2-dropdown .select2-search__field {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  color: #555;
  font-family: "Helvetica", "Arial", sans-serif;
  font-size: 16px;
}
.select2-container .select2-search--inline .select2-search__field {
  height: 24px;
}
.Splashscreen {
  width: 100%;
  min-height: 100%;
  background: linear-gradient(rgba(28, 171, 160, 0.7), rgba(28, 171, 160, 0.7)), url(/static/media/background.e134a83c.jpg);
  background-size: cover;
  background-position: center;
  overflow: hidden;
  padding: 50px 0;
}
.Splashscreen .logo {
  background-size: contain;
  height: 600px;
  height: 70vh;
  width: 80%;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 1s;
  /*-webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.45, 0.05, 0.55, 0.95);
    animation-timing-function: cubic-bezier(0.45, 0.05, 0.55, 0.95);*/
}
.Splashscreen.withBox .logo {
  height: 160px;
}
.Splashscreen .splashBox {
  max-width: 450px;
  margin: auto;
  margin-top: 30px;
}
.Splashscreen .splashBox.wide {
  max-width: 900px;
}
@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
@keyframes pulse {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
  50% {
    -webkit-transform: scale3d(0.9, 0.9, 0.9);
    transform: scale3d(0.9, 0.9, 0.9);
  }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}
.sk-folding-cube {
  margin: 20px auto;
  margin-top: 40px;
  width: 20px;
  height: 20px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}
.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00bcd4;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}
.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
.Chat {
  overflow-x: auto;
}
.Chat > div {
  min-width: 600px;
}
.Chat .ConversationBar {
  height: calc(100vh - 125px);
  width: 250px;
  float: left;
}
.Chat .ConversationBar .Box {
  height: calc(100% - 50px);
}
.Chat .ConversationBar .Box .box-content {
  overflow: auto;
  height: calc(100% - 44px);
}
.Chat .ConversationBar .user {
  border-bottom: #e6e6e6 1px solid;
  overflow: hidden;
  padding: 8px 14px;
  padding-right: 10px;
  margin: 0 -16px;
  transition: padding-left 0.3s, border-left-color 0.3s;
  border-left: 2px solid transparent;
}
.Chat .ConversationBar .user.active {
  border-left-color: #204056;
  padding-left: 16px;
  background: #f8f8f8;
}
.Chat .ConversationBar .user.has_message {
  background-color: #f8ffc1;
}
.Chat .ConversationBar .user p {
  display: table;
  height: 40px;
}
.Chat .ConversationBar .user p span {
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
}
.Chat .ConversationBar .user:hover {
  background: rgba(208, 208, 208, 0.2);
}
.Chat .ConversationBar .user:hover.has_message {
  background-color: #f1f8b9;
}
.Chat .Conversation {
  height: calc(100vh - 125px);
  width: calc(100% - 265px);
  margin-left: 15px;
  float: left;
  overflow: hidden;
  position: relative;
}
.Chat .Conversation.Placeholder {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 15px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  border: 2px dashed rgba(102, 102, 102, 0.27);
  border-radius: 5px;
  flex-direction: column;
  align-items: center;
}
.Chat .Conversation.Placeholder h1 {
  font-size: 18px;
  margin-top: 15px;
  color: #3b3b3b;
}
.Chat .Conversation .wrapper {
  overflow: auto;
  max-height: calc(100vh - 175px);
  position: absolute;
  bottom: 50px;
  left: 0;
  right: 0;
  padding: 16px;
}
.Chat .Conversation .messageInput {
  height: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
.Chat .Conversation .messageInput textarea {
  min-height: 50px;
  resize: none;
  overflow: hidden;
  background: rgba(208, 208, 208, 0.2);
  display: block;
  width: 100%;
  padding: 10px 16px;
  padding-right: 40px;
  font-size: 16px;
  outline: none;
  border: none;
}
.Chat .Conversation .messageInput .fa-paper-plane {
  position: absolute;
  right: 16px;
  bottom: 16px;
}
.Chat .Conversation .Message {
  overflow: hidden;
  margin-top: 5px;
}
.Chat .Conversation .Message span {
  display: inline-block;
  background: rgba(208, 208, 208, 0.2);
  border-radius: 10px;
  padding: 5px 10px;
  max-width: 65%;
}
.Chat .Conversation .Message span.left {
  float: left;
}
.Chat .Conversation .Message span.right {
  float: right;
  background-color: #e1edf3;
}
.Chat .Conversation .Message .__react_component_tooltip {
  padding: 4px 10px;
  font-size: 11px;
}
.Chat ::-webkit-scrollbar-button {
  display: none;
}
.Chat ::-webkit-scrollbar-thumb {
  background-color: #1CABA0;
  border-radius: 5px;
}
.Chat ::-webkit-scrollbar-thumb:hover {
  background-color: #1c958a;
}
.Chat ::-webkit-scrollbar-track {
  background-color: #efefef;
  border-radius: 5px;
}
.Chat ::-webkit-scrollbar {
  width: 6px;
}
div#react-confirm-alert {
  z-index: 1051;
  position: absolute;
}
.react-confirm-alert {
  margin: auto;
}
.react-confirm-alert-overlay {
  -webkit-animation: react-confirm-alert-fadeIn 0.3s 0s forwards;
  animation: react-confirm-alert-fadeIn 0.3s 0s forwards;
  background: rgba(0, 0, 0, 0.3);
  overflow: auto;
  padding-bottom: 10px;
}
.react-confirm-alert-body {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 1px -1px rgba(0, 0, 0, 0.2), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  padding: 16px;
  margin-top: 15px;
  overflow: auto;
  background-color: white;
  border-radius: 0;
  color: #404040;
  width: 90vw;
  max-width: 600px;
  font-family: inherit;
  line-height: 1.5;
  font-size: 16px;
}
.react-confirm-alert-body h1 {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 20px;
}
.react-confirm-alert-body p:not(:first-child) {
  margin-top: 10px;
}
.react-confirm-alert-body.wide {
  max-width: 850px;
}
.react-confirm-alert-button-group {
  display: block;
  text-align: center;
}
.react-confirm-alert-blur {
  -webkit-filter: none;
  filter: none;
}
.react-confirm-alert-blur > :first-child {
  -webkit-filter: url(#gaussian-blur);
  filter: url(#gaussian-blur);
  filter: blur(2px);
  -webkit-filter: blur(2px);
}
.react-confirm-alert-blur ~ .select2-container .select2-dropdown:not(.modal) {
  -webkit-filter: blur(2px);
          filter: blur(2px);
}
.select2-container .select2-selection--multiple .select2-selection__rendered {
  white-space: normal;
  white-space: initial;
}
.m-input-moment .btn-save {
  display: none;
}
.m-calendar td {
  padding: 4px 0;
}
.m-input-moment .tab {
  height: auto;
}
.m-input-moment {
  max-width: 450px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 13px 10px 10px 10px;
  margin-top: 5px;
  background-color: #f4f4f4;
  position: absolute;
  box-shadow: 2px 2px 4px rgba(17, 38, 60, 0.56);
  z-index: 1;
}
.m-input-moment .options {
  display: none;
}
.m-input-moment .m-calendar {
  float: left;
  display: inline-block !important;
  width: 224px;
}
.m-input-moment .m-time {
  min-width: 182px;
  width: 40%;
  margin-left: 18px;
  display: inline-block;
}
.m-calendar table {
  background-color: white;
}
.m-input-moment .tabs {
  margin-bottom: 3px;
  display: inline;
}
.m-calendar .toolbar button {
  background: #204056;
  color: white;
  border: 0;
  box-shadow: 0 2px 0 rgba(90, 112, 128, 0.82);
}
.m-calendar .toolbar button:active {
  background: #274e69;
  transition: none;
  box-shadow: none;
  top: 2px;
}
.m-calendar .toolbar button:hover {
  background: #1d3a4f;
}
.m-input-moment .options button {
  color: #204056;
  border: 1px solid #204056;
}
.m-input-moment .options button.is-active {
  background-color: #204056;
}
.m-calendar .toolbar .current-date {
  color: #204056;
}
.m-calendar thead td {
  color: #204056;
}
.m-calendar .current-day {
  color: #204056;
}
.m-calendar tbody td:hover {
  background: #204056;
  border: 0;
  color: #fff;
}
.m-time {
  padding: 15px 5px;
}
.m-time .separater,
.m-time .time-text {
  color: #204056;
}
.m-time .time {
  background: #204056;
  height: 56px;
  line-height: 56px;
}
.u-slider-time .value {
  background: #204056;
}
.u-slider-time .handle:after {
  top: -10px;
  left: -9px;
  width: 18px;
  height: 18px;
  border: 3px solid #204056;
}

